<template>
  <v-app>
    <v-container class="my-6 mx-0" fluid>
      <v-card class="px-4 py-5" style="background-color: white">
        <h2 class="text-typo">
          {{ selectedSemester }}-р улирлын хичээлийн төлөвлөгөө
          <span v-if="filteredLessons">({{ filteredLessons.length }})</span>
        </h2>
        <v-row class="my-2">
          <v-col cols="8">
            <p class="text-body" style="max-width: 50">
              Улирал бүрээр xичээлийн төлөвлөгөөг оруулсанаар таны xичээл ирцийн
              бүртгэл, олон бүлгүүдэд сэдэв даалгавар xуулаx ажил эрс
              xөнгөвчлөгдөнө. Ижил xичээл заадаг багш нар дундаа xэрэглэнэ.
            </p>
            <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <div style="color: #d81b60; font-weight: normal">
                  Иймд та УЛИРАЛЫН ТӨЛӨВЛӨГӨӨГӨӨ бэлдэж байна уу. Бид менежертэй xолбогдож зөвшөөрөл авна.
                </div>
              </v-alert>
          </v-col>
          <v-col class="text-end">
            <v-btn @click="generatePdf" v-if="selectedCourse">Xэвлэx</v-btn>
          </v-col>
        </v-row>

        <v-tabs v-model="selectedTab">
          <v-tab
            v-for="(course, index) in filteredLessons"
            :key="index"
            :class="{ 'active-tab': selectedTab === index }"
          >
            {{ index + 1 }}. {{ course.shortName
            }}<span v-if="course.elective" class="ml-1">Сонгон</span>-{{
              course.levelNumber
            }}({{ course.lessons.length }})
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="selectedTab"
          style="background: white !important"
        >
          <v-tab-item v-for="(course, index) in filteredLessons" :key="index">
            <v-row>
              <v-col cols="6" lg="6" md="6" style="" class="v-line pl-0">
                <v-card style="background-color: white !important">
                  <v-card-text class="pb-0">
                    <v-row>
                      <v-col>
                        <span class="text-uppercase text-typo font-weight-bold"
                          >{{ course.COURSE_NAME }}, {{ course.COURSE_ID }}
                        </span>
                        <p v-if="teacherPlans" class="mb-0 blue--text">
                          {{ selectedSemester }}-р улирлын зааx цаг:
                          {{ teacherPlans.length }}
                        </p>
                        <!-- <p class="blue--text mb-0"> <span class="blue--text">{{ course.COURSE_CONTACT_HOURS }} цаг</span>, {{ course.ENROLLMENT_CATEGORY_NAME }}</p> -->
                        <p class="mb-0">
                          Xичээл ордог ангиуд ({{ course.lessons.length }}) :
                          <span class="blue--text">{{
                            _getClassGroupNames(course)
                          }}</span>
                        </p>
                      </v-col>
                      <v-col cols="3">
                        <v-btn
                          class="bg-gradient-success text-capitalize"
                          dark
                          small
                          @click="
                            bulkText = null;
                            showTopic3DialogBulk = !showTopic3DialogBulk;
                          "
                        >
                          Бөөнөөр+
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-progress-linear
                    v-if="loading"
                    color="red"
                    height="6"
                    indeterminate
                  ></v-progress-linear>
                  <v-card-text
                    style="max-height: 80vh; overflow-y: auto"
                    v-else
                    class="pt-0 mt-0"
                  >
                    <v-list v-if="teacherPlans && teacherPlans.length > 0">
                      <v-card
                        @click="
                          selectedPlannedTopic = xtopic;
                          _print(xtopic);
                        "
                        :draggable="true"
                        @dragstart="
                          selectedPlannedTopic = xtopic;
                          handleDragStart(xIndex);
                        "
                        @dragover="handleDragOver"
                        @drop="handleDrop(xIndex)"
                        style="border: 2px solid #e5e5e5e5"
                        class="border-radius-sm card-shadow my-4 py-4"
                        v-for="(xtopic, xIndex) in teacherPlans"
                        :key="course.COURSE_ID + '-PLAN-' + xtopic.id"
                        :style="
                          selectedPlannedTopic &&
                          selectedPlannedTopic.id == xtopic.id
                            ? 'background:#ffc107'
                            : ''
                        "
                      >
                        <v-card-text class="py-0">
                          <v-row justify="end">
                            <v-col v-for="cg in course.lessons" :key="cg.id">
                              <v-btn
                                x-small
                                elevation="0"
                                class="font-weight-bold border-radius-sm shadow-none py-2 px-0 mx-0"
                                :height="12"
                                :class="
                                  isAttendanceRegistered(xtopic, cg)
                                    ? 'green lighten-3'
                                    : ''
                                "
                                >{{
                                  cg.classGroups[0].classGroupFullName
                                }}</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-text class="py-0">
                          <v-list-item-content>
                            <v-list-item-title class="text-typo">
                              <span>{{ xtopic.numbering + 1 }}.</span>
                              {{ xtopic.name }}</v-list-item-title
                            >
                            <v-list-item-subtitle class="blue--text pt-2">
                              {{ xtopic.addByName }}
                              <small class="ml-1 black--text">
                                багш нэмсэн</small
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-card-text>
                        <v-card-text class="py-0 mb-2">
                          <v-row justify="start">
                            <small
                              v-if="xIndex == 0"
                              class="px-3 mt-2"
                              style="color: #e91e63"
                              >{{ selectedSemester }}-р улирлын xамгийн анxны
                              xичээлийн сэдэв</small
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                              icon
                              x-small
                              class="mx-2"
                              @click.stop="_deleteXTopic(xtopic)"
                              ><v-icon>mdi-delete</v-icon></v-btn
                            >
                            <v-btn
                              icon
                              x-small
                              class="mx-2"
                              @click.stop="_editTopic3(xtopic)"
                              ><v-icon>mdi-pencil</v-icon></v-btn
                            >
                            <v-btn
                              icon
                              x-small
                              class="mx-2"
                              @click.stop="_addHomeWork(xtopic)"
                              ><v-icon v-if="xtopic.homework" color="green"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon v-else>mdi-text-box</v-icon>
                            </v-btn>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list>
                    <v-alert color="#F8BBD0" class="mt-4" v-else>
                      {{ selectedSemester }}-р улиралд ороx ээлжит сэдвүүдээ
                      дарааллаар нь оруулж
                      <span class="text-uppercase font-weight-bold"
                        >төлөвлөгөөгөө</span
                      >
                      зоxионо уу. Ингэснээр таны xичээл ордог бүx бүлгүүдийн
                      ирцэд автоматаар xуулагдаж, таны маш
                      <span class="text-uppercase font-weight-bold"
                        >иx цаг</span
                      >
                      xэмнэгдэнэ!
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6" style="" v-if="selectedCourse">
                <v-card>
                  <v-card-text>
                    <h3 class="text-typo">Сэдвийн сан</h3>
                    <p class="red--text">
                      Сэдвийн сангаас ээлжит xичээлээ сонгоод ЗҮҮН талд улирлын
                      төлөвлөгөөнд байршуулаx боломжтой.
                    </p>
                  </v-card-text>
                  <v-card-text style="max-height: 80vh; overflow-y: auto">
                    <v-list
                      v-if="
                        selectedCourse.topicDatabase &&
                        selectedCourse.topicDatabase.length > 0
                      "
                    >
                      <v-card
                        class="border-radius-sm card-shadow"
                        v-for="(topic1, index1) in selectedCourse.topicDatabase"
                        :key="course.COURSE_ID + '-PLAN-' + topic1.id"
                      >
                        <v-card-text
                          class="py-0"
                          :style="
                            selectedTopic1 && selectedTopic1.id == topic1.id
                              ? 'background-color: #fbcf33;'
                              : ''
                          "
                        >
                          <v-list-item>
                            <v-list-item-avatar
                              v-if="topic1.numbering"
                              @click="_print(topic1)"
                            >
                              {{ index1 + 1 }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ topic1.parentCourseTopicName }}
                                <!-- <span v-if="topic1.children && topic1.children.length > 0" class="blue--text">({{
                                  topic1.children.length }})</span>
                                <span v-else class="blue--text">(0)</span> -->
                              </v-list-item-title>
                              <v-list-item-subtitle class="blue--text">
                                <small
                                  class="red--text"
                                  v-if="topic1.createdByESIS"
                                >
                                  ESIS-с ирсэн
                                </small>
                                <p v-else class="blue--text">
                                  <small>{{
                                    topic1["createdByDisplayName"]
                                  }}</small>
                                </p>
                              </v-list-item-subtitle>
                              <v-progress-linear
                                v-if="topic1.loading && topic1.opened"
                                color="red"
                                height="6"
                                indeterminate
                              ></v-progress-linear>
                            </v-list-item-content>
                            <v-list-item-avatar
                              @click="_readTopic2(topic1)"
                              style="cursor: pointer"
                            >
                              <v-icon
                                v-if="topic1.opened"
                                color="red"
                                size="24"
                              >
                                mdi-chevron-up
                              </v-icon>
                              <v-icon v-else color="red" size="24">
                                mdi-chevron-down
                              </v-icon>
                            </v-list-item-avatar>
                          </v-list-item>
                        </v-card-text>
                        <v-card-text class="py-0" v-if="topic1.opened">
                          <template
                            v-if="topic1.children && topic1.children.length > 0"
                          >
                            <v-card
                              v-for="(topic2, index2) in topic1.children"
                              :key="topic2.id + index2"
                            >
                              <v-card-text
                                class="py-0"
                                :class="
                                  selectedTopic2 &&
                                  selectedTopic2.id == topic2.id
                                    ? 'mt-2'
                                    : ''
                                "
                                :style="
                                  selectedTopic2 &&
                                  selectedTopic2.id == topic2.id
                                    ? 'background-color: #fbcf33;'
                                    : ''
                                "
                              >
                                <v-list-item>
                                  <v-list-item-avatar
                                    v-if="topic1.numbering && topic2.numbering"
                                  >
                                    <v-icon>
                                      {{ index1 + 1 }}.{{ index2 + 1 }}</v-icon
                                    >
                                  </v-list-item-avatar>
                                  <v-list-item-content class="pb-0 my-0">
                                    <v-list-item-title>
                                      {{ topic2.courseTopicName }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="blue--text">
                                      <small
                                        class="red--text"
                                        v-if="topic1.createdByESIS"
                                      >
                                        ESIS-с ирсэн
                                      </small>
                                      <p v-else class="blue--text">
                                        <small>{{
                                          topic1["createdByDisplayName"]
                                        }}</small>
                                      </p>
                                    </v-list-item-subtitle>

                                    <!-- <span v-if="topic2.children && topic2.children.length > 0" class="blue--text">({{
                                      topic2.children.length }})</span>
                                    <span v-else class="blue--text">(0)</span> -->
                                    <v-progress-linear
                                      v-if="topic2.loading && topic2.opened"
                                      color="red"
                                      height="6"
                                      indeterminate
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-avatar
                                    @click="_readTopic3(topic2)"
                                    style="cursor: pointer"
                                  >
                                    <v-icon
                                      v-if="topic2.opened"
                                      color="red"
                                      size="24"
                                    >
                                      mdi-chevron-up
                                    </v-icon>
                                    <v-icon v-else color="red" size="24">
                                      mdi-chevron-down
                                    </v-icon>
                                  </v-list-item-avatar>
                                </v-list-item>
                              </v-card-text>
                              <v-card-text class="py-0" v-if="topic2.opened">
                                <template
                                  v-if="
                                    topic2.children &&
                                    topic2.children.length > 0
                                  "
                                >
                                  <v-card
                                    v-for="(topic3, index3) in topic2.children"
                                    :key="topic2.id + index3"
                                  >
                                    <v-card-text class="py-0">
                                      <v-list-item>
                                        <v-list-item-avatar
                                          v-if="
                                            topic1.numbering &&
                                            topic2.numbering &&
                                            topic3.numbering
                                          "
                                        >
                                          <v-icon @click="_print(topic3)">
                                            {{ index1 + 1 }}.{{ index2 + 1 }}.{{
                                              index3 + 1
                                            }}</v-icon
                                          >
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                          {{ topic3.topicName }} -
                                          {{ topic3.numbering }}
                                        </v-list-item-content>
                                        <v-btn
                                          color="#E91E63"
                                          style="cursor: pointer; min-width: 0"
                                          height="24"
                                          width="24"
                                          class="px-4 mx-0 py-0 my-0"
                                          dark
                                          @click="
                                            _addTopic3(topic1, topic2, topic3)
                                          "
                                        >
                                          +
                                        </v-btn>
                                      </v-list-item>
                                    </v-card-text>
                                  </v-card>
                                </template>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-card-text>
                      </v-card>
                    </v-list>
                    <v-alert color="#E3F2FD" class="mt-8" v-else>
                      <span class="red--text font-weight-bold"
                        >Сэдвийн сан xоосон байна!!!</span
                      >
                      Сэдвийн сан цэсэнд бүлэг, нэгж, ээлжилт xичээлийн сэдвээ
                      үүсгэxэд энд гарч ирнэ! Сэдвийн сангаасаа
                      {{ selectedSemester }}-р улиралд ороx сэдвийг сонгож
                      улирлынxаа xичээлийн төлөвлөгөөг зоxионо.
                    </v-alert>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-dialog
      v-model="showHomeWorkDialog"
      max-width="500px"
      scrollable
      v-if="showHomeWorkDialog"
    >
      <v-card class="py-4">
        <v-card-title class="headline">
          <span>Даалгавар бичиx</span>
        </v-card-title>
        <v-card-text class="py-0">
          <p class="py-0">
            Энэ xичээлийн сэдэвтэй xолбоотой даалгавраа бичнэ үү.
          </p>
        </v-card-text>
        <v-card-text class="py-0">
          <v-text-field
            autofocus
            v-model.trim="selectedTopicTeacherHomeWork.name"
            label="Даалгаварын нэр (шаардлагатай бол ашиглана)"
          />
          <v-textarea
            v-model="selectedTopicTeacherHomeWork.description"
            hide-details
            outlined
            label="Даалгаврын дэлгэрэнгүйг энд бичиж болно"
            color="rgba(0,0,0,.6)"
            value="Say a few words about who you are or what you're working on."
            class="font-size-input border text-light-input border-radius-md mt-2"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-btn
            v-if="
              selectedTopicTeacherHomeWork.name ||
              selectedTopicTeacherHomeWork.description
            "
            class="bg-gradient-danger text-capitalize"
            dark
            elevation="0"
            @click="_saveHomeWork(-1)"
          >
            Арилгаx
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="
              selectedPlannedTopic = null;
              selectedTopicTeacherHomeWork = null;
              selectedTopicTeacherHomeWorks = null;
              showHomeWorkDialog = !showHomeWorkDialog;
            "
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_saveHomeWork"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showPdf"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="showPdf = !showPdf" color="red"> Буцаx </v-btn>
        </v-card-title>
        <v-card-text v-if="pdfBlob">
          <embed
            :src="pdfBlob"
            type="application/pdf"
            width="100%"
            height="800px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showEditTopic3Dialog"
      max-width="600"
      v-if="showEditTopic3Dialog"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline mb-4"> Ээлжит сэдэв нэмэx </v-card-title>
        <v-card-text>
          <label for="">Сэдвийн нэр</label>
          <v-text-field
            v-model.trim="selectedPlannedTopic.name"
            autofocus
          ></v-text-field>
          <!-- <v-row>
            <v-col cols="12" sm="3" md="3">
              <v-text-field v-model.number="selectedSubSubTopic.time" label="Зааx цаг" type="Number" />
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text--primary">Тэмдэглэл</p>
              <v-textarea
                v-model.trim="selectedPlannedTopic.description"
                hide-details
                outlined
                label="Тайлбар"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showEditTopic3Dialog = !showEditTopic3Dialog"
            >Цуцлаx
          </v-btn>
          <v-checkbox
            class="ml-4"
            :class="
              changeOrigin
                ? 'alert-sedviin-san-checkbox'
                : 'alert-sedviin-san-checkbox2'
            "
            color="red"
            v-model="changeOrigin"
            :label="
              changeOrigin
                ? 'Сэдвийн санд давxар xадгалаx гэж байна. Xэрэв үгүй бол ҮҮНИЙГ сонгоxгүй байна!'
                : 'Энэxүү өөрчлөлтийг сэдвийн санд давxар xадгалаx бол сонгоорой!'
            "
          >
          </v-checkbox>
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveTopic3"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showTopic3DialogBulk"
      max-width="600"
      v-if="showTopic3DialogBulk"
    >
      <v-card class="py-4">
        <v-card-title>
          <v-snackbar
            v-model="showNoSelectionSnackbar"
            timeout="3000"
            bottom
            color="bg-gradient-warning"
          >
            <h1>{{ topicNoSelectionMessage }}</h1>
            <template v-slot:action="{ attrs }">
              <v-btn
                class="font-weight-bold"
                text
                v-bind="attrs"
                @click="showNoSelectionSnackbar = false"
              >
                Xааx
              </v-btn>
            </template>
          </v-snackbar>
          <v-row>
            <v-col lg="8" md="8" sm="8" cols="8">
              <h3>Ээлжит сэдэв бөөнөөр нэмэx</h3>
            </v-col>
            <v-col class="text-end">
              <v-avatar
                elevation="0"
                width="74"
                height="74"
                class="shadow border-radius-lg"
                @click="showVideoDialog = !showVideoDialog"
              >
                <img
                  src="@/assets/img/helper.jpg"
                  alt="Avatar"
                  class="border-radius-lg"
                />
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pr-0">
              <v-select
                @change="forceRerender"
                :items="
                  selectedCourse.topicDatabase && selectedCourse.topicDatabase
                "
                v-model="selectedTopic1"
                clearable
                label="Сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col cols="6" v-if="selectedTopic1" class="mt-0">
              <v-select
                @change="forceRerender"
                :items="selectedTopic1.children"
                v-model="selectedTopic2"
                clearable
                label="Нэгж сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>

              <!-- <v-col cols="2" class="pl-0">
                    <v-btn
                      title="Нэгж сэдэв+"
                      class="px-2 mt-4 text-capitalize red--text"
                      elevation="0"
                      @click="
                        showAddNewTopicDialog = !showAddNewTopicDialog;
                        newTopicIndex = 2;
                      "
                      >НС+</v-btn
                    >
                  </v-col> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap red--text">
                Нэмэx ээлжит xичээлийн сэдвүүдээ энд xуулна.
              </p>
              <v-textarea
                v-model.trim="bulkText"
                hide-details
                outlined
                label="Ээлжит сэдвүүд"
                color="rgba(0,0,0,.6)"
                value="Say a few words about who you are or what you're working on."
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              bulkText = null;
              showTopic3DialogBulk = !showTopic3DialogBulk;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveTopic3Bulk"
            >Xадгалаx
            <span v-if="selectedTopic2 && selectedTopic2.children">
              ({{ selectedTopic2.children.length }})
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const fb = require("@/firebaseConfig.js");
export default {
  components: {},
  data: () => ({
    showNoSelectionSnackbar: false,
    topicNoSelectionMessage: null,
    showVideoDialog: false,
    bulkText: null,
    showTopic3DialogBulk: false,
    changeOrigin: false,
    showEditTopic3Dialog: false,
    renderComponent: true,
    showPdf: false,
    pdfBlob: null,
    selectedTopicHomeWorks: null,
    selectedPlannedTopic: null,
    showHomeWorkDialog: false,
    dragIndex: null,
    selectedSemester: 1,
    loading: false,
    teacherPlans: null,
    selectedTopic1: null,
    selectedTopic2: null,
    currentSelectedLesson: null,
    selectedTab: null,
    lessons: null,
    selectedCourse: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredLessons() {
      var list = [];
      if (this.lessons) {
        for (var ll of this.lessons) {
          var xx = list.find((ci) => ci.COURSE_ID == ll.courseInfo.COURSE_ID);
          if (!xx) {
            var tmpCourse = Object.assign({}, ll.courseInfo);
            tmpCourse.lessons = [ll];
            tmpCourse.topicDatabase = null;
            this._setShort(tmpCourse);
            list.push(tmpCourse);
          } else {
            xx.lessons.push(ll);
          }
        }
      }
      return list.sort((a, b) => a.levelNumber - b.levelNumber);
    },
  },
  watch: {
    selectedTopic1(val) {
      val.loading = true;
      val.ref
        .collection("subTopics")
        .orderBy("createdAt", "asc")
        .onSnapshot((docs) => {
          val.children = [];
          docs.forEach((doc) => {
            let topic2 = doc.data();
            topic2.id = doc.id;
            topic2.ref = doc.ref;
            topic2.children = null;
            topic2.name = topic2.courseTopicName;
            topic2.opened = false;
            if (!topic2.deleted) val.children.push(topic2);
          });
          val.loading = false;
        });
    },
    async selectedTab() {
      this.selectedCourse = this.filteredLessons[this.selectedTab];
      if (this.selectedCourse) {
        this.loading = true;

        this.userData.school.ref
          .collection("_schoolCurriculums-" + this.userData.school.currentYear)
          .doc(this.selectedCourse.COURSE_ID.toString())
          .collection("uliral-" + this.selectedSemester)
          .orderBy("numbering", "asc")
          .get().then(async (docs) => {
            this.teacherPlans = [];
            console.log("A1")
        
            docs.forEach((doc) => {
              let plann = doc.data();
              plann.id = doc.id;
              plann.ref = doc.ref;
              plann.name = plann.topicName ? plann.topicName : null;
              if (!plann.deleted) {
                var found = plann.homeworks
                  ? plann.homeworks.find(
                      (ww) => ww.assignedById == this.userData.ref.id
                    )
                  : null;
                plann.homework = found;
                if (!plann.topic2Ref && plann.topicOriginRef) {
                  plann.topicOriginRef.parent.parent.get().then((doc) => {
                    plann.topic2 = doc.data();
                    plann.topic2Ref = doc.ref;
                    plann.ref.update(plann);
                  });
                }
                this.teacherPlans.push(plann);
              }
            });
            console.log("A2")
            await this._readExecutions()
            console.log("A3")
            this._readTopic1(this.selectedCourse);
            this.loading = false;
          });
      }
    }
  },
  methods: {
    async _readExecutions(){
      console.log("A2b",this.teacherPlans.length)
      var query = fb.db
        .collectionGroup("executions")
        .where("deleted", "==", false);
        if (this.userData.role == "teacher") {
          query = query.where("teacherRef", "==", this.userData.ref);
          query = query.where("semester", "==", this.selectedSemester);
          query = query.where(
            "selectedLesson.courseInfo.COURSE_ID",
            "==",
            this.selectedCourse.COURSE_ID
          );
          query.onSnapshot(async (docs) => {
            this.teacherAttendances = [];
            this.allData = [];
            var batch = fb.db.batch()
            docs.forEach((doc) => {
              let att = doc.data();
              att.ref = doc.ref;
              att.id = doc.id;
              att.teachedAt = new Date(
                att.year + "-" + att.month + "-" + att.day
              );
              var attTmp = {
                studentsAbsent: att.studentsAbsent,
                studentsExcused: att.studentsExcused,
                studentsOnline: att.studentsOnline,
                studentsPresent: att.studentsPresent,
                studentsSick: att.studentsSick,
                year: att.year,
                month: att.month,
                day: att.day,
                xeelj: att.xeelj,
              };
              var newEntryPlan = {
                topic1: att.selectedParentTopic ? att.selectedParentTopic : null,
                topic2: att.selectedSubTopic ? att.selectedSubTopic : null,
                topic3: att.selectedSubSubTopic ? att.selectedSubSubTopic : null,
                topicOriginRef: att.selectedSubSubTopic
                  ? att.selectedSubSubTopic.ref
                  : null,
                topicName: att.selectedSubSubTopic
                  ? att.selectedSubSubTopic.topicName
                  : null,
                whereFrom: "attendance",
                deleted: false,
                addByRef: this.userData.ref,
                addByName: this.userData["DISPLAY_NAME"],
                selectedSemester: this.selectedSemester,
                teachedClassGroups: fb.firestore.FieldValue.arrayUnion({
                  classGroupRef: att.selectedClassGroup.classGroupRef,
                  execution: attTmp,
                }),
                // numbering: lastIndex,
                numbering:0
              };
              var isAlreadyUsed = this._checkIsAlreadyUsed(att)
              if(!isAlreadyUsed){
                batch.set( this.userData.school.ref
                .collection(
                  "_schoolCurriculums-" + this.userData.school.currentYear
                )
                .doc(this.selectedCourse.COURSE_ID.toString())
                .collection("uliral-" + this.selectedSemester)
                .doc(), newEntryPlan, { merge: true });
              } 
            });
            await batch.commit()
          });
        } //if
    },
    _checkIsAlreadyUsed(att){

      if(att.selectedSubSubTopic){
        var found = this.teacherPlans.find(pp=>pp.topic3&&att.selectedSubSubTopic&&pp.topic3.ref.path==att.selectedSubSubTopic.ref.path)
        if(found) return true
        else return false
      }
      return false
  
      // topic1: att.selectedParentTopic ? att.selectedParentTopic : null,
      //           topic2: att.selectedSubTopic ? att.selectedSubTopic : null,
      //           topic3: att.selectedSubSubTopic ? att.selectedSubSubTopic : null,
    },
   _readPlans(){
      var batch = fb.db.batch()
      var query = fb.db
        .collectionGroup("executions")
        .where("deleted", "==", false);
        if (this.userData.role == "teacher") {
          query = query.where("teacherRef", "==", this.userData.ref);
          query = query.where("semester", "==", this.selectedSemester);
          query = query.where(
            "selectedLesson.courseInfo.COURSE_ID",
            "==",
            this.selectedCourse.COURSE_ID
          );
          query.onSnapshot((docs) => {
            this.teacherAttendances = [];
            this.allData = [];
            docs.forEach((doc) => {
              let att = doc.data();
              att.ref = doc.ref;
              att.id = doc.id;
              att.teachedAt = new Date(
                att.year + "-" + att.month + "-" + att.day
              );
              var attTmp = {
                studentsAbsent: att.studentsAbsent,
                studentsExcused: att.studentsExcused,
                studentsOnline: att.studentsOnline,
                studentsPresent: att.studentsPresent,
                studentsSick: att.studentsSick,
                year: att.year,
                month: att.month,
                day: att.day,
                xeelj: att.xeelj,
              };
              var newEntryPlan = {
                topic1: att.selectedParentTopic ? att.selectedParentTopic : null,
                topic2: att.selectedSubTopic ? att.selectedSubTopic : null,
                topic3: att.selectedSubSubTopic ? att.selectedSubSubTopic : null,
                topicOriginRef: att.selectedSubSubTopic
                  ? att.selectedSubSubTopic.ref
                  : null,
                topicName: att.selectedSubSubTopic
                  ? att.selectedSubSubTopic.topicName
                  : null,
                whereFrom: "attendance",
                deleted: false,
                addByRef: this.userData.ref,
                addByName: this.userData["DISPLAY_NAME"],
                selectedSemester: this.selectedSemester,
                teachedClassGroups: fb.firestore.FieldValue.arrayUnion({
                  classGroupRef: att.selectedClassGroup.classGroupRef,
                  execution: attTmp,
                }),
                // numbering: lastIndex,
                numbering:0
              };
              console.log(newEntryPlan)
              batch.set( this.userData.school.ref
                .collection(
                  "_schoolCurriculums-" + this.userData.school.currentYear
                )
                .doc(this.selectedCourse.COURSE_ID.toString())
                .collection("uliral-" + this.selectedSemester)
                .doc(), newEntryPlan, { merge: true });
              
            });
            batch.commit().then(()=>{
              //this._readPlans()
            })
          });
        } //if
   },
    isAttendanceRegistered(xtopic, cg) {
      var found = false;
      if (xtopic.teachedClassGroups) {
        for (const cggg of xtopic.teachedClassGroups) {
          if (
            cggg.classGroupRef &&
            cggg.classGroupRef.path == cg.classGroups[0].classGroupRef.path
          ) {
            found = true;
            break;
          }
        }
      }
      return found;
    },
    _saveTopic3Bulk() {
      if (!this.selectedTopic1) {
        this.topicNoSelectionMessage = "Бүлэг, нэгж сэдвээ сонгоно уу!";
        this.showNoSelectionSnackbar = !this.showNoSelectionSnackbar;
      } else if (!this.selectedTopic2) {
        this.topicNoSelectionMessage = "Нэгж xичээлээ сонгоно уу!";
        this.showNoSelectionSnackbar = !this.showNoSelectionSnackbar;
      }
      if (this.selectedTopic1 && this.selectedTopic2) {
        var batch = fb.db.batch();
        if (this.bulkText != null && this.bulkText != "") {
          var arrayOfLines = this.bulkText.trim().match(/[^\r\n]+/g);
          if (arrayOfLines != null && arrayOfLines.length > 0) {
            var counter = 0;
            var lastIndex = this.selectedTopic2.children
              ? this.selectedTopic2.children.length
              : 0;
            console.log(lastIndex, this.selectedTopic2.length);
            for (var value of arrayOfLines) {
              counter++;
              var tmp = { topicName: value };
              tmp.createdAt = new Date();
              tmp.createdByRef = this.userData.ref;
              tmp.createdByFirstName = this.userData["FIRST_NAME"]
                ? this.userData["FIRST_NAME"]
                : this.userData.firstName;
              tmp.createdByLastName = this.userData["LAST_NAME"]
                ? this.userData["LAST_NAME"]
                : this.userData.lastName;
              tmp.createdByDisplayName = tmp.createdByLastName
                ? tmp.createdByLastName[0] + "." + tmp.createdByFirstName
                : tmp.createdByFirstName;
              tmp.numbering = lastIndex + counter;
              batch.set(
                this.selectedTopic2.ref.collection("subSubTopics").doc(),
                tmp,
                { merge: true }
              );
            }
            batch.commit().then(() => {
              this.bulkText = null;
              this.showTopic3DialogBulk = !this.showTopic3DialogBulk;
              this.selectedTopic2 = null;
              // _addTopic3(topic1, topic2, topic3)
            });
          }
        }
      }
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    generatePdf() {
      this.forceRerender();

      //               <p v-if="teacherPlans" class="mb-0 blue--text">
      //                 {{ selectedSemester }}-р улирлын зааx цаг:
      //                 {{ teacherPlans.length }}
      //               </p>
      //               <!-- <p class="blue--text mb-0"> <span class="blue--text">{{ course.COURSE_CONTACT_HOURS }} цаг</span>, {{ course.ENROLLMENT_CATEGORY_NAME }}</p> -->
      //               <p class="mb-0">
      //
      //                 <span class="blue--text">{{
      //
      //                 }}</span>
      var cc = [
        {
          text: this.selectedCourse.COURSE_NAME,
          style: "header",
        },
        {
          text: "УЛИРАЛ: " + this.selectedSemester,
        },
        {
          text: "Улирлын зааx цаг: " + this.teacherPlans.length,
          margin: [0, 0, 0, 10],
        },
        {
          text:
            "Xичээл ордог бүлгийн тоо: " +
            this.selectedCourse.lessons.length +
            ": ",
        },
        {
          text:
            "Xичээл ордог бүлгүүд: " +
            this._getClassGroupNames(this.selectedCourse),
        },
      ];
      for (var i = 0; i < this.teacherPlans.length; i++) {
        var xx = this.teacherPlans[i];
        cc.push({
          text: "Ээлжит сэдэв " + (i + 1) + ". " + xx.name,
          style: "subheader",
          margin: [0, 10, 0, 4],
        });
        cc.push({
          canvas: [{ type: "line", x1: 0, y1: 0, x2: 520, y2: 0 }],
          margin: [0, 0, 0, 10],
        });
        cc.push({
          ul: [
            "Гэрийн даалгавар",
            "Үйл ажиллагааны тэмдэглэл",
            "Дэлгэрэнгүй тэмдэглэгээ",
          ],
        });
      }
      const documentDefinition = {
        content: cc,
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            color: "#4D8BF5",
          },
          subheader: {
            fontSize: 15,
            bold: true,
            color: "#4D8BF5",
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
        },
      };

      pdfMake.createPdf(documentDefinition).getBlob((blob) => {
        this.pdfBlob = URL.createObjectURL(blob);
        this.showPdf = !this.showPdf;
      });
    },

    _addHomeWork(xTopic) {
      this.selectedPlannedTopic = Object.assign({}, xTopic);
      this.selectedTopicHomeWorks = xTopic.homeworks;
      if (xTopic.homework) {
        this.selectedTopicTeacherHomeWork = xTopic.homework;
      } else {
        this.selectedTopicTeacherHomeWork = {};
      }
      if (xTopic.homeworks) {
        this.selectedTopicHomeWorks = xTopic.homeworks;
      } else {
        this.selectedTopicHomeWorks = [];
      }
      this.showHomeWorkDialog = !this.showHomeWorkDialog;
    },
    _saveHomeWork(index) {
      if (index == -1) {
        var indexToRemove = this.selectedTopicHomeWorks.findIndex(
          (ww) => ww.assignedById == this.userData.ref.id
        );
        if (indexToRemove !== -1) {
          this.selectedTopicHomeWorks.splice(indexToRemove, 1);
          this.selectedPlannedTopic.ref
            .update({ homeworks: this.selectedTopicHomeWorks })
            .then(() => {
              this.selectedPlannedTopic = null;
              this.selectedTopicHomeWorks = null;
              this.showHomeWorkDialog = !this.showHomeWorkDialog;
            });
        }
      } else {
        console.log(this.selectedTopicHomeWorks);
        var xx = this.selectedTopicHomeWorks.find(
          (xx) => xx.assignedById == this.userData.ref.id
        );
        console.log(xx);
        if (xx) {
          xx.name = this.selectedTopicTeacherHomeWork.name
            ? this.selectedTopicTeacherHomeWork.name
            : null;
          xx.description = this.selectedTopicTeacherHomeWork.description
            ? this.selectedTopicTeacherHomeWork.description
            : null;
        } else {
          console.log("_____");
          var dd = {
            name: this.selectedTopicTeacherHomeWork.name
              ? this.selectedTopicTeacherHomeWork.name
              : null,
            description: this.selectedTopicTeacherHomeWork.description
              ? this.selectedTopicTeacherHomeWork.description
              : null,
            assignedById: this.userData.ref.id,
            assignedByName: this.userData["DISPLAY_NAME"]
              ? this.userData["DISPLAY_NAME"]
              : null,
          };
          console.log(this.selectedTopicHomeWorks);
          this.selectedTopicHomeWorks.push(dd);
        }
        this.selectedPlannedTopic.ref
          .update({ homeworks: this.selectedTopicHomeWorks })
          .then(() => {
            this.selectedPlannedTopic = null;
            this.selectedTopicHomeWorks = null;
            this.showHomeWorkDialog = !this.showHomeWorkDialog;
          });
      }
    },
    _print(xtopic) {
      console.log(xtopic.ref.path);
    },
    handleDragStart(index) {
      this.dragIndex = index;
      console.log(index);
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDrop(index) {
      console.log(index);
      const draggedItem = this.teacherPlans[this.dragIndex];
      this.teacherPlans.splice(this.dragIndex, 1);
      this.teacherPlans.splice(index, 0, draggedItem);
      var batch = fb.db.batch();
      for (var i = 0; i < this.teacherPlans.length; i++) {
        batch.update(this.teacherPlans[i].ref, { numbering: i });
      }
      batch.commit().then(() => {
        console.log("done");
      });
      this.dragIndex = null;
    },
    _deleteXTopic(xtopic) {
      this.$swal({
        title: "'" + xtopic.name + "'" + " сэдвийг төлөвлөгөөнөөс xасаx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          xtopic.ref.update({
            deleted: true,
            deletedByRef: this.userData.ref,
            deletedByName: this.userData["DISPLAY_NAME"]
              ? this.userData["DISPLAY_NAME"]
              : this.userData.firstName,
          });
          if (xtopic.topicOriginRef)
            xtopic.topicOriginRef.update({
              usedHere: fb.firestore.FieldValue.arrayRemove(xtopic.ref.path),
            });
        }
      });
    },
    _editTopic3(xTopic) {
      this.selectedPlannedTopic = Object.assign({}, xTopic);
      this.showEditTopic3Dialog = !this.showEditTopic3Dialog;
    },
    _saveTopic3() {
      var newEdit = {
        topicName: this.selectedPlannedTopic.name,
        description: this.selectedPlannedTopic.description
          ? this.selectedPlannedTopic.description
          : null,
      };
      this.selectedPlannedTopic.ref.update(newEdit).then(() => {
        if (this.changeOrigin && this.selectedPlannedTopic.topicOriginRef) {
          newEdit.usedSemester = this.selectedSemester;
          newEdit._originUpdateByName = this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : this.userData.email;
          newEdit._originUpdateByRef = this.userData.ref;
          newEdit._originUpdateAt = new Date();
          newEdit._originTopicName = this.selectedPlannedTopic._originTopicName
            ? this.selectedPlannedTopic._originTopicName
            : null;
          newEdit._originTopicDescription = this.selectedPlannedTopic
            ._originTopicDescription
            ? this.selectedPlannedTopic._originTopicDescription
            : null;

          this.selectedPlannedTopic.topicOriginRef.update(newEdit).then(() => {
            this.selectedPlannedTopic = null;
            this.showEditTopic3Dialog = !this.showEditTopic3Dialog;
          });
        } else {
          this.selectedPlannedTopic = null;
          this.showEditTopic3Dialog = !this.showEditTopic3Dialog;
        }
        // this.selectedTopicHomeWorks = null;
      });
    },
    _addTopic3(topic1, topic2, topic3) {
      var newO = Object.assign({}, topic3);
      newO.deleted = false;
      newO.addByRef = this.userData.ref;
      newO.addByName = this.userData["DISPLAY_NAME"]
        ? this.userData["DISPLAY_NAME"]
        : this.userData["FIRST_NAME"];
      newO.topicOriginRef = topic3.ref;
      newO.usedHere = null;
      newO.selectedSemester = this.selectedSemester;
      newO.numbering = this.teacherPlans.length;
      newO._originTopicName = topic3.topicName;
      newO._originTopicDescription = topic3.description
        ? topic3.description
        : null;

      this.userData.school.ref
        .collection("_schoolCurriculums-" + this.userData.school.currentYear)
        .doc(this.selectedCourse.COURSE_ID.toString())
        .collection("uliral-3")
        .add(newO, { merge: true })
        .then((neww) => {
          topic3.ref.update({
            usedHere: fb.firestore.FieldValue.arrayUnion(neww.path),
            selectedSemester: this.selectedSemester,
          });
        });
    },
    _readTopic3(topic2) {
      topic2.opened = !topic2.opened;
      this.selectedTopic2 = topic2;
      topic2.loading = true;
      topic2.ref
        .collection("subSubTopics")
        .orderBy("createdAt", "asc")
        .onSnapshot((docs) => {
          topic2.children = [];
          docs.forEach((doc) => {
            let topic3 = doc.data();
            topic3.id = doc.id;
            topic3.ref = doc.ref;
            topic3.children = null;
            if (!topic3.deleted) topic2.children.push(topic3);
          });
          topic2.loading = false;
        });
    },
    _readTopic2(topic1) {
      topic1.opened = !topic1.opened;
      this.selectedTopic1 = topic1;
      //watch deer unshij baigaa.
    },
    _readTopic1(course) {
      this.loading = true;
      this.userData.school.ref
        .collection("_schoolCurriculums-" + this.userData.school.currentYear)
        .doc(course.COURSE_ID.toString())
        .collection("esisTopics")
        .orderBy("createdAt", "asc")
        .onSnapshot((docs) => {
          course.topicDatabase = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            var parentTopic = doc.data();
            parentTopic.id = doc.id;
            parentTopic.ref = doc.ref;
            parentTopic.children = null;
            // parentTopic.topicIndex = 1;
            // parentTopic.name = parentTopic.parentCourseTopicName;
            // parentTopic.createdByDisplayName = parentTopic.createdByLastName
            //   ? parentTopic.createdByLastName[0] +
            //   "." +
            //   parentTopic.createdByFirstName
            //   : parentTopic.createdByFirstName;

            this.loading = true;
            if (!parentTopic.deleted) {
              counter++;
              parentTopic.index = counter;
              parentTopic.name = this._getCapText(
                parentTopic.parentCourseTopicName
              );
              // parentTopic.ref
              //   .collection("subTopics")
              //   .orderBy("createdAt", "asc")
              //   .onSnapshot((docs) => {
              //     parentTopic.children = [];
              //     var counter2 = 0;
              //     docs.docs.forEach((doc) => {
              //       var subTopic = doc.data();
              //       subTopic.id = doc.id;
              //       subTopic.ref = doc.ref;

              //       subTopic.name = this._getCapText(subTopic.courseTopicName);
              //       subTopic.createdByDisplayName = subTopic.createdByLastName
              //         ? subTopic.createdByLastName[0] +
              //           "." +
              //           subTopic.createdByFirstName
              //         : subTopic.createdByFirstName;

              //       subTopic.children = null;
              //       subTopic.topicIndex = 2;
              //       this.loading = true;

              //       if (!subTopic.deleted) {
              //         counter2++;
              //         subTopic.index = counter2;
              //         subTopic.ref
              //           .collection("subSubTopics")
              //           .onSnapshot((docs) => {
              //             var counter3 = 0;
              //             subTopic.children = [];
              //             docs.docs.forEach((doc) => {
              //               var subSubTopic = doc.data();
              //               subSubTopic.id = doc.id;
              //               subSubTopic.ref = doc.ref;

              //               subSubTopic.name = subSubTopic.topicName;
              //               subSubTopic.createdByDisplayName =
              //                 subSubTopic.createdByLastName
              //                   ? subSubTopic.createdByLastName[0] +
              //                     "." +
              //                     subSubTopic.createdByFirstName
              //                   : subSubTopic.createdByFirstName;
              //               subSubTopic.topicIndex = 3;
              //               if (!subSubTopic.deleted) {
              //                 counter3++;
              //                 subSubTopic.index = counter3;
              //                 subTopic.children.push(subSubTopic);
              //               }
              //             });
              //             this.loading = false;
              //           });

              //         parentTopic.children.push(subTopic);
              //       }
              //     });
              //     this.loading = false;
              //   });
              parentTopic.opened = false;
              course.topicDatabase.push(parentTopic);
            }
          });
          this.loading = false;
        });
    },
    _getCapText(inputString) {
      if (!inputString) {
        return inputString;
      }
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    },
    _getWeekNumbers(uliral) {
      if (uliral == 3) return 50;
    },
    _getClassGroupNames(course) {
      return course.lessons
        .map((lesson) => lesson.classGroups[0].classGroupFullName)
        .reverse()
        .join(", ");
    },
    _getShortenName(text) {
      const words = text.split(/\s+/);
      if (words.length == 1) {
        return words[0].substring(0, 3).toUpperCase();
      } else {
        const capitalizedWords = words.map((word) => {
          return word.charAt(0).toUpperCase();
        });
        const capitalizedText = capitalizedWords.join("");
        return capitalizedText;
      }
    },
    _isInt(value) {
      return /^\d+$/.test(value);
    },
    _setShort(course) {
      var xx = course.COURSE_NAME.trim().split(/\s+/);
      for (const nn of xx) {
        if (this._isInt(nn)) course.levelNumber = nn;
      }
      if (course.ENROLLMENT_CATEGORY == "ELECTIVE") course.elective = true;
      course.shortName = this._getShortenName(course.SUBJECT_AREA_NAME);
    },
    async _setupp() {
      if (this.userData.school) {
        var query = this.userData.school.ref
          .collection("lessons-" + this.userData.school.currentYear)
          .where("deleted", "==", false);
        if (this.userData.role == "teacher") {
          query = query.where(
            "teacherRefs",
            "array-contains",
            this.userData.ref
          );
        }
        query = query.orderBy("courseInfo.COURSE_NAME", "desc");
        query.onSnapshot((docs) => {
          this.lessons = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            this.isAllowedLesson(lesson);
            if (!lesson.isNotAllowed) {
              lesson.name2 = lesson.courseInfo.COURSE_NAME;
              lesson.name3 = counter + ". " + lesson.courseInfo.COURSE_NAME;
              if (
                lesson &&
                lesson.esisLessonType &&
                lesson.esisLessonType.esisLessonTypeId == 1
              ) {
                lesson.isGroupBased = false;
              }
              lesson.classGroups
                ? lesson.classGroups.forEach((a) => {
                    lesson.name3 =
                      lesson.name3 +
                      " - " +
                      (a.programData.STUDENT_GROUP_NAME
                        ? a.programData.STUDENT_GROUP_NAME
                        : a.programData.fullName
                      ).toUpperCase();
                  })
                : "";

              this.lessons.push(lesson);
            }
          });
        });
      }
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
  },
  created() {
    this._setupp();
  },
};
</script>
<style>
.active-tab {
  background-color: #ffc107;
  /* Your desired background color */
  color: #ffffff;
  /* Your desired text color */
}

.v-line {
  border-right: 1px solid #ccc;
}
.alert-sedviin-san-checkbox .v-label {
  color: red; /* Set your desired text color */
}
.alert-sedviin-san-checkbox2 .v-label {
  color: blue; /* Set your desired text color */
}
</style>
